import React, { useState, useEffect } from 'react';
import './App.css';

const App = () => {
  const [usdPrice, setUsdPrice] = useState('');
  const [cadPrice, setCadPrice] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1.36);  // Default value
  const [loading, setLoading] = useState(true);

  // Break-even analysis inputs
  const [localGasPrice, setLocalGasPrice] = useState(1.50);  // Local CAD gas price, separate from the conversion
  const [mileage, setMileage] = useState(12);  // km/l
  const [distance, setDistance] = useState(70);  // km
  const [tankCapacity, setTankCapacity] = useState(42);  // liters
  const [savingsOrLoss, setSavingsOrLoss] = useState(0);

  // For showing/hiding break-even section
  const [showBreakEven, setShowBreakEven] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5010/api/v1';
  const logoPath = process.env.REACT_APP_LOGO_PATH || `${process.env.PUBLIC_URL}/logo.png`;

  useEffect(() => {
    fetch(`${apiUrl}/rate`)
      .then((response) => response.json())
      .then((data) => {
        setExchangeRate(data.rate);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching exchange rate:', error);
        setLoading(false);
      });
  }, [apiUrl]);

  // Handling real-time conversion changes
  const handleUsdChange = (e) => {
    setUsdPrice(e.target.value);
    setCadPrice((e.target.value * exchangeRate / 3.78541).toFixed(2));  // Convert gallons to liters
  };

  const handleCadChange = (e) => {
    setCadPrice(e.target.value);
    setUsdPrice((e.target.value * 3.78541 / exchangeRate).toFixed(2));  // Convert liters to gallons
  };

  // Break-even analysis calculation
  const calculateSavingsOrLoss = () => {
    if (!usdPrice || !localGasPrice) return;

    // Cost of fuel in U.S. for a full tank
    const costInUs = usdPrice * (tankCapacity / 3.78541);  // USD (since tank capacity is in liters)
    const costInCad = costInUs * exchangeRate;  // Convert USD cost to CAD

    // Cost at local place in Canada for a full tank
    const costInCanada = localGasPrice * tankCapacity;  // CAD

    // Travel cost (round trip distance in km / mileage) * local gas price per liter
    const travelCost = (2 * distance / mileage) * localGasPrice;  // Round-trip travel cost in CAD

    // Savings or Loss
    const result = costInCanada - (costInCad + travelCost);
    setSavingsOrLoss(result);
  };

  // Call calculation whenever the input values change
  useEffect(() => {
    calculateSavingsOrLoss();
  }, [usdPrice, localGasPrice, mileage, distance, tankCapacity, exchangeRate]);

  if (loading) {
    return <p>Loading exchange rate...</p>;
  }

  return (
    <div className="app-container">
      <header>
        <img src={logoPath} alt="Website Logo" className="logo" />
      </header>
      <h1>USD to CAD Gas Price Calculator</h1>
      
      {/* Display the current exchange rate */}
      <p>The current USD to CAD exchange rate is <label>{exchangeRate.toFixed(2)}</label></p>

      <div className="converter-container">
        <div>
          <label>Price in USD per Gallon:</label>
          <input 
            type="number" 
            value={usdPrice} 
            onChange={handleUsdChange} 
            placeholder="USD $" 
          />
        </div>
        <div className="switch-button">
          <button>⇌</button>
        </div>
        <div>
          <label>Converted Price in CAD per Litre:</label>
          <input 
            type="number" 
            value={cadPrice} 
            onChange={handleCadChange} 
            placeholder="CAD $" 
            readOnly
          />
        </div>
      </div>

      {/* Collapsible Break-even Section */}
      <div className="breakeven-container">
        <button onClick={() => setShowBreakEven(!showBreakEven)} className="breakeven-toggle">
          {showBreakEven ? 'Hide' : 'Show'} Cost Basis Analysis
        </button>

        {showBreakEven && (
          <div className="breakeven-content">
            <h2>Break-even Analysis</h2>
            <div>
              <label>Local Price of Gas in CAD/Litre :</label>
              <input 
                type="number" 
                value={localGasPrice} 
                onChange={(e) => setLocalGasPrice(parseFloat(e.target.value))} 
                placeholder="Local CAD price" 
              />
            </div>
            <div>
              <label>Mileage of your car (km/l):</label>
              <input 
                type="number" 
                value={mileage} 
                onChange={(e) => setMileage(parseFloat(e.target.value))} 
                placeholder="Mileage (km/l)" 
              />
            </div>
            <div>
              <label>Distance to gas station(one-way in km):</label>
              <input
                type="number" 
                value={distance} 
                onChange={(e) => setDistance(parseFloat(e.target.value))} 
                placeholder="Distance (km)" 
              />
            </div>
            <div>
              <label>Car full tank capacity (liters):</label>
              <input 
                type="number" 
                value={tankCapacity} 
                onChange={(e) => setTankCapacity(parseFloat(e.target.value))} 
                placeholder="Tank Capacity (liters)" 
              />
            </div>

            
            
            {/* Display the result of the break-even analysis */}
            <p className="result">
              Cost Basis Analysis Recommendation: 
              <strong style={{ color: savingsOrLoss >= 0 ? 'green' : 'red' }}>
                {savingsOrLoss >= 0 ? 'Savings' : 'Loss'}
              </strong>
            </p>
            <p className="result">
              Average {savingsOrLoss >= 0 ? 'Savings' : 'Cost'}: 
              <strong style={{ color: savingsOrLoss >= 0 ? 'green' : 'red' }}>
                {savingsOrLoss >= 0 ? `+${savingsOrLoss.toFixed(2)} CA$` : `${savingsOrLoss.toFixed(2)} CA$`}
              </strong>
            </p>
          </div>
        )}
      </div>
      <br/>
      {/* Please Note section */}
      <p><strong>Please note:</strong> Some Canadian credit cards charge a conversion fee (usually around 2.5%) on top of the preferred exchange rate set by the bank.</p>
      
      {/* Legal Disclaimer */}
      <p className="disclaimer">
        <strong>Legal Disclaimer:</strong> The information provided by this calculator is for informational purposes only. It should not be considered financial advice, and the exchange rates are based on the latest data available. Please verify with your bank or card provider for exact rates and fees.
      </p>
    </div>
  );
};

export default App;
